<template>
  <div v-if="loaded" class="h-full" ref="gridcontainer">
    <div class="flex flex-col h-full text-xs">
      <div class="bg-red-200">
        <div class="py-1 px-2 bg-gray-700 text-white flex justify-between">
          <div>
            <span class="mr-2">{{ layout.translation ? layout.translation.label : " - " }}</span>
            <cm-button @click="showForm(null)" size="small" visual="success">{{ $t("button.create") }}</cm-button>
          </div>

          <div>{{ $tc("module.tab.entries", data ? data.length : 0) }}</div>
        </div>
      </div>
      <div class="flex-grow overflow-auto">
        <table class="relative w-full border">
          <thead class="text-left border-b">
            <tr>
              <th v-if="pmData" @click="setSort('pm_registration_id')" class="sticky top-0 bg-white w-4">
                <div class="flex items-center">
                  <a href="#" slot="reference" class="top">
                    <span class="px-1"> {{ $t("pm.pm") }}</span>
                  </a>

                  <span v-show="sortcolumn == 'pm_registration_id'">
                    <svg
                      v-if="sortasc"
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      class="w-4 h-4"
                    >
                      <path d="M5 10l7-7m0 0l7 7m-7-7v18" />
                    </svg>
                    <svg
                      v-if="!sortasc"
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      class="w-4 h-4"
                    >
                      <path d="M19 14l-7 7m0 0l-7-7m7 7V3" />
                    </svg>
                  </span>
                </div>
              </th>
              <th
                v-for="(header, key) in tab.structure"
                :key="key"
                @click="setSort(header.name)"
                class="sticky top-0 bg-white"
              >
                <div class="flex items-center">
                  <a href="#" slot="reference" class="top">
                    <span class="px-1 mr-1 whitespace-nowrap">{{ header.translation.label }}</span>
                  </a>

                  <span v-show="header.name == sortcolumn">
                    <svg
                      v-if="sortasc"
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      class="w-4 h-4"
                    >
                      <path d="M5 10l7-7m0 0l7 7m-7-7v18" />
                    </svg>
                    <svg
                      v-if="!sortasc"
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      class="w-4 h-4"
                    >
                      <path d="M19 14l-7 7m0 0l-7-7m7 7V3" />
                    </svg>
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody v-if="tab" :style="dynamicGridHeight" class="">
            <tr
              v-for="(row, key) in sortedData"
              :key="key"
              @click="showForm(row.id)"
              :class="isHoveredOnGraph(row.id)"
              class="group odd:bg-gray-100 first:border-t border-b border-gray-200"
            >
              <td
                v-if="pmData"
                class="px-2 group-hover:bg-blue-100 group-hover:cursor-pointer border-transparent whitespace-nowrap font-bold"
                :class="{ 'text-green-500': row.data.pm_registration_id }"
              >
                {{ row.data.pm_registration_id ? "&#10003;" : "" }}
              </td>
              <td
                v-for="(header, key) in tab.structure"
                :key="key"
                :class="isHoveredOnGraph(row.id, header.name)"
                class="px-2 group-hover:bg-blue-100 group-hover:cursor-pointer border-transparent whitespace-nowrap"
              >
                {{ row.data[header.name] || row.calculations[header.name] }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { sortByColumn } from "@/utils/grid.js";

export default {
  name: "ModuleGrid",
  props: ["height"],
  data() {
    return {
      loaded: false,
      name: null,
      sortcolumn: null,
      sortasc: true,
      gridHeight: null,
      hoveredId: null,
      hoverListener: null,
      clickListener: null,
    };
  },
  watch: {
    height: {
      immediate: false,
      handler: "dynamicHeight",
    },
  },
  created() {
    this.load();
  },
  mounted() {
    this.addListeners();
  },
  computed: {
    // ...mapState("moduledata", {
    // data: state => state.data,
    // tab: state => state.tab,
    // layout: state => state.layout,
    // }),
    ...mapGetters("meta", {
      tabcolumns: "tabcolumns",
    }),
    data() {
      return this.$store.getters["moduledata/getModuleData"](this.name);
    },
    tab() {
      return this.$store.getters["moduledata/getFormTab"](this.name);
    },
    layout() {
      return this.$store.getters["moduledata/getFormLayout"](this.name);
    },

    dynamicGridHeight() {
      return this.gridHeight;
    },
    sortedData() {
      if (!this.sortcolumn) return this.data;

      const variableInfo = this.tab.structure.find(d => d.name === this.sortcolumn);

      //if (!variableInfo) return this.data;

      const columnType = variableInfo?.type ? variableInfo.type : "integer";

      return sortByColumn(JSON.parse(JSON.stringify(this.data)), this.sortcolumn, columnType, this.sortasc);
    },
    pmData() {
      if (!this.data) return false;

      return this.data.filter(d => d.data.pm_registration_id).length ? true : false;
    },
  },
  methods: {
    isHoveredOnGraph(id, varname) {
      return {
        "bg-orange-300 text-gray-700": this.hoveredId === id,
        "font-semibold text-gray-900": this.hoveredId === id && this.hoveredVarname === varname,
      };
    },
    async load() {
      this.name = this.$route.query.name;
      await this.$store.dispatch("moduledata/load", this.name);

      this.loaded = true;
    },
    setSort(name) {
      this.sortasc = name === this.sortcolumn ? !this.sortasc : this.sortasc;
      this.sortcolumn = name;
    },
    dynamicHeight() {
      this.$nextTick(() => {
        const th = this.$refs.tableheader ? this.$refs.tableheader.clientHeight : 50;

        this.gridHeight = { height: this.height - th + "px" };
      });
    },
    async showForm(rowid) {
      const rowdata = rowid ? this.data.find(d => +d.id == +rowid) : null;

      if (rowdata) {
        await this.$store.dispatch("moduledata/setFormdata", { formname: this.name, data: rowdata });
      } else {
        await this.$store.dispatch("moduledata/resetFormdata", this.name);
      }

      const structure = {
        layout: this.layout,
        data: rowdata,
        id: rowid,
      };

      this.$modal.show("form", structure);
    },
    addListeners() {
      this.$nextTick(() => {
        this.hoverListener = window.addEventListener("graph-dot-hover", d => {
          const id = d.detail.id;
          const varname = d.detail.varname;
          this.hoveredId = id ? id : null;
          this.hoveredVarname = varname ? varname : null;
        });
        this.clickListener = window.addEventListener("graph-dot-click", d => {
          const id = d.detail.id;
          this.showForm(id).then(() => {
            return true;
          });
        });
      });
    },
  },
  destroyed() {
    window.removeEventListener("graph-dot-hover", this.hoverListener);
    window.removeEventListener("graph-dot-click", this.clickListener);
  },
};
</script>
