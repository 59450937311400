const sortByColumn = (data, columnName, columnType, asc = false) => {
    if (columnName == 'date') {
        return data.sort((a, b) => {
            return asc ? new Date(a['observation_date']) - new Date(b['observation_date']) : new Date(b['observation_date']) - new Date(a['observation_date']);
        })
    }

    switch (columnType) {
        case "string":
        case "mediumtext":
        case "text":
            return data.sort((a, b) => {
                let l, r;
                [l, r] = asc ? [a, b] : [b, a];
                const aValue = '' + l.data[columnName] || '' + l.calculations[columnName] || '';
                const bValue = '' + r.data[columnName] || '' + r.calculations[columnName] || '';

                return aValue.localeCompare(bValue);
            })

        default:
            return data.sort((a, b) => {
                let l, r;
                [l, r] = asc ? [a, b] : [b, a];

                const aValue = l.data[columnName] || l.calculations[columnName] || null;
                const bValue = r.data[columnName] || r.calculations[columnName] || null;

                return +aValue - +bValue;
            })
    }
}

export {
    sortByColumn
}